/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}